import styled from "styled-components";

export const Main = styled.div`

background: rgba(255,255,255, 0.5);
min-height: 100vh;
display: flex;
flex-direction: column;
align-items: center;

`

export const Section = styled.div`

display: flex;
flex-direction: column;
align-items: center;
width: 100%;
margin: 1.5rem;

.links{
    width: 90%;
}

`



export const Links = styled.div`


`


